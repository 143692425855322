<template>
  <div id="app">
    <w-app id="app" block>
      <header>
        <img src="./assets/header-monitoring-70.png" left alt="Logo FRAKO GmbH" />        
      </header> 
      <w-toolbar bg-color="green-light5">
          <div class="title2">Monitoring</div>
          <div class="spacer"></div>        
          <w-button icon="mdi mdi-menu" text lg class="ml3 mdu-hide" @click="openDrawer = true"></w-button>
        </w-toolbar>       
      <w-drawer v-model="openDrawer"> 
        <w-button
          @click="openDrawer = false"
          icon="wi-cross"
          sm
          outline
          round
          absolute>
        </w-button>       
        <w-flex column align-start justify-start class="wrapper">
          <div class="title3">Momentanwerte</div>
          <w-button text lg @click="openDrawer = false, menu.showCurrent = false, menu.showPower = false, menu.showVoltage = true, menu.showBar = false, menu.showImpress = false">Spannung</w-button>
          <w-button text lg @click="(openDrawer = false, menu.showVoltage = false, menu.showPower = false, menu.showCurrent = true, menu.showBar = false, menu.showImpress = false)">Strom</w-button>
          <w-button text lg @click="(openDrawer = false, menu.showVoltage = false, menu.showCurrent = false, menu.showPower = true, menu.showBar = false, menu.showImpress = false)">Leistung</w-button>
          <w-button text lg @click="(openDrawer = false, menu.showVoltage = false, menu.showCurrent = false, menu.showPower = false, menu.showBar = true, menu.showImpress = false)">Balken</w-button>
          <w-button text lg @click="(openDrawer = false, menu.showVoltage = false, menu.showCurrent = false, menu.showPower = false, menu.showBar = false, menu.showImpress = true)">Impressum</w-button>
        </w-flex>                                            
      </w-drawer>
      <w-alert error v-show="error.show">{{error.text}}</w-alert>
      <w-flex grow>        
        <Voltage v-show="menu.showVoltage" /> 
        <Current v-show="menu.showCurrent" />      
        <Power v-show="menu.showPower" />
        <Barview v-show="menu.showBar" /> 
        <Impressum v-show="menu.showImpress" />       
        <aside class="smd-hide">
          <w-flex column align-start justify-start class="wrapper">
            <div class="title3">Momentanwerte</div>
            <w-button text lg @click="openDrawer = false, menu.showCurrent = false, menu.showPower = false, menu.showVoltage = true, menu.showBar = false, menu.showImpress = false">Spannung</w-button>
            <w-button text lg @click="(openDrawer = false, menu.showVoltage = false, menu.showPower = false, menu.showCurrent = true, menu.showBar = false, menu.showImpress = false)">Strom</w-button>
            <w-button text lg @click="(openDrawer = false, menu.showVoltage = false, menu.showCurrent = false, menu.showPower = true, menu.showBar = false, menu.showImpress = false)">Leistung</w-button>
            <w-button text lg @click="(openDrawer = false, menu.showVoltage = false, menu.showCurrent = false, menu.showPower = false, menu.showBar = true, menu.showImpress = false)">Balken</w-button>
            <w-button text lg @click="(openDrawer = false, menu.showVoltage = false, menu.showCurrent = false, menu.showPower = false, menu.showBar = false, menu.showImpress = true)">Impressum</w-button>
          </w-flex>
        </aside>
      </w-flex>
      <footer>
        <w-flex>
          <div class="xs10">
            <div class="text-left text-middle body">Copyright © FRAKO Kondensatoren- und Anlagenbau GmbH. Alle Rechte vorbehalten</div>
          </div>
          <div class="xs2">
            <div class="text-right body">V{{version}}</div>
          </div>
        </w-flex>
      </footer>
    </w-app>
  </div>
  
</template>

<script>
import { version } from 'os';
import '@mdi/font/css/materialdesignicons.min.css'
import Voltage from './views/Voltage.vue'
import Current from './views/Current.vue'
import Power from './views/Power.vue'
import Barview from './views/Barview.vue'
import Impressum from './views/impressum.vue'

export default {
  name: 'App',
  components: { 
    Voltage ,
    Current,
    Power,
    Barview, 
    Impressum   
  },
  data() {
    return {
      version: '',      
      openDrawer: false,
      menu: {
        showVoltage: true,
        showCurrent: false,
        showPower: false, 
        showBar: false, 
        showImpress: false,
      },
      error: {
        show: false,
        text: '',
      },      
    }
  },
  inject: ['$callHub'], 
  mounted: async function () {
            await fetch('api/version')
                .then(function (response) {
                    if (response.ok)
                        return response.json()
                })
                .then(data => this.version = data)

            this.$callHub.client.on("ShowError", (data) => {
              this.error.text = JSON.parse(data);
              this.error.show = true;
            });
            this.$callHub.client.on("hideError", (data) => {              
              this.error.show = false;
            });
            setTimeout(() => {  this.$callHub.client.invoke("SingleGetDaten"); }, 1000);            
          }
}
</script>

<style>
/* Demo styles - can be discarded. */
.w-app {padding: 4px; font-family: Avenir, Helvetica, Arial, sans-serif;}
aside, main {
  margin: 4px;
  padding: 12px;
  text-transform: uppercase;
  /* color: #666; */
  /* border: 1px solid rgba(0, 0, 0, 0.07); */
}
footer {background-color: lightgrey; min-height: 30px; padding: 12px;}
header {background:url(./assets/wave-70.png); height:70px;}
/* aside {background-color: #fef2ff;} */
/* main {background-color: #efffed;} */
</style>
