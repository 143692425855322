<template>
    <w-flex wrap>                             
        <div class="xs12 sm12 md4 lg4 xl4 pa1">
          <div class="warning-light3--bg py3 title1">
            <div class="pa3 text-left">Strom eff L1</div>
            <div class="pa3 text-right">{{currentL1}} A</div>
          </div>
        </div>
        <div class="xs12 sm12 md4 lg4 xl4 pa1">
          <div class="warning-light3--bg py3 title1">
            <div class="pa3 text-left">Strom eff L2</div>
            <div class="pa3 text-right">{{currentL2}} A</div>
          </div>
        </div>
        <div class="xs12 sm12 md4 lg4 xl4 pa1">
          <div class="warning-light3--bg py3 title1">
            <div class="pa3 text-left">Strom eff L3</div>
            <div class="pa3 text-right">{{currentL3}} A</div>
          </div>
        </div>            
      </w-flex>
</template>
    
<script>
export default {
  name: 'Current', 
  data() {
    return {
      currentL1: '--,--',
      currentL2: '--,--',
      currentL3: '--,--',      
    }
  },
  inject: ['$callHub'], 
  mounted: function ()  {
    this.$callHub.client.on("SendData_Current", (data) => {
      var currentdata = JSON.parse(data);
      this.currentL1 = currentdata.Current_L1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.currentL2 = currentdata.Current_L2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.currentL3 = currentdata.Current_L3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });      
    });
  } 
}
</script>