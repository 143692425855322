<template>
    <w-flex wrap class="text-center">
        <div class="xs12 pa3 title1">Impressum</div>
        <div class="xs12 px3 title3">Frako Kondensatoren- und Anlagenbau GmbH</div>
        <div class="xs12 px3 title3">Tscheulinstrasse 21a</div>
        <div class="xs12 px3 pb3 title3">D-79331 Teningen</div>        
        <div class="xs12 px3 title3">Tel: +49 7641 453-0</div>
        <div class="xs12 px3 title3">Fax: +49 7641 453-535</div>
        <div class="xs12 px3 pb5 title3"><w-button class="ma1" color="primary" text lg force-link route="http://www.frako.com">http://www.frako.com</w-button></div>
        <div class="xs12 px3 title3">Geschäftsführer: Dr. Matthias Sehmsdorf</div>
        <div class="xs12 px3 title3">Handelsregister: HRB 701735 Freiburg</div>
        <div class="xs12 px3 title3">USt.-IdNr.: DE 136 687 642</div>
    </w-flex>
</template>

<script>
export default {
  name: 'Impressum', 
  data() {
    return {
      
    }
  },
}
</script>
