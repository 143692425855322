import { createApp } from 'vue'
import App from './App.vue'
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
import VueApexCharts from "vue3-apexcharts";
import CallHub from "./callHub";

CallHub.start();
const app = createApp(App);
app.use(VueApexCharts);
app.provide('$callHub', CallHub); // Providing to all components during app creation     provide / inject

new WaveUI(app, {
  // Some Wave UI options.
});

app.mount('#app');