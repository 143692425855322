<template>
    <w-flex wrap>  
        <BarComponent name="Wirkleistung" unit="kWh" :value1="wirk1" :value2="wirk2" :value3="wirk3" :valuetotal="wirktotal" bgcolor="success-light1--bg"/>
        <BarComponent name="Blindleistung" unit="kvar" :value1="blind1" :value2="blind2" :value3="blind3" :valuetotal="blindtotal" bgcolor="success-light2--bg"/>
        <BarComponent name="Scheinleistung" unit="kVA" :value1="schein1" :value2="schein2" :value3="schein3" :valuetotal="scheintotal" bgcolor="success-light3--bg"/>        
    </w-flex>
</template>

<script>
import BarComponent from '../components/BarComponent.vue'
export default {
  name: 'Barview', 
  components: {
    BarComponent
  },
  data() {
    return {      
      wirk1: 0,
      wirk2: 0,
      wirk3: 0,
      wirktotal: 0,
      blind1: 0,
      blind2: 0,
      blind3: 0,
      blindtotal: 0,
      schein1: 0,
      schein2: 0,
      schein3: 0,
      scheintotal: 0,
    }
  },
  inject: ['$callHub'], 
  mounted: function ()  {
    this.$callHub.client.on("SendData_Power", (data) => {
      var powerdata = JSON.parse(data);       
      this.wirk1 = powerdata.PowerActive_L1;
      this.wirk2 = powerdata.PowerActive_L2; 
      this.wirk3 = powerdata.PowerActive_L3; 
      this.wirktotal = powerdata.PowerActive_total; 
      this.blind1 = powerdata.PowerReactive_L1;
      this.blind2 = powerdata.PowerReactive_L2;
      this.blind3 = powerdata.PowerReactive_L3;
      this.blindtotal = powerdata.PowerReactive_total;
      this.schein1 = powerdata.PowerApparent_L1;
      this.schein2 = powerdata.PowerApparent_L2;
      this.schein3 = powerdata.PowerApparent_L3;
      this.scheintotal = powerdata.PowerApparent_total;
    });
  }
}
</script>