import { HubConnectionBuilder } from "@microsoft/signalr";

class CallHub {
    constructor() {
        this.client = new HubConnectionBuilder()
        .withUrl("api/pqmdata")
        .withAutomaticReconnect()
        .build();
    }

    start() {
        this.client.start();
    }
}

export default new CallHub();