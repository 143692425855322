<template>
    <div class="xs12 sm12 md6 lg4 xl4 pa1">
        <div class="py2 title3" :class="bgColorClass">
            <div class="xs12 pa3 text-left">{{name}}</div>   
            <w-flex row align-center>
                <div class="px3">L1</div>
                <w-progress class="grow" :model-value="v1_proz" size="0.8em" round round-cap></w-progress>                
                <div class="px3 text-right" style="width: 130px">{{ compValue1 }} {{unit}}</div> 
            </w-flex>              
            <w-flex row align-center>
                <div class="px3">L2</div>
                <w-progress class="grow" :model-value="v2_proz" size="0.8em" round round-cap></w-progress>
                <div class="px3 text-right" style="width: 130px">{{ compValue2 }} {{unit}}</div> 
            </w-flex>   
            <w-flex row align-center>
                <div class="px3">L3</div>
                <w-progress class="grow" :model-value="v3_proz" size="0.8em" round round-cap></w-progress>
                <div class="px3 text-right" style="width: 130px">{{ compValue3 }} {{unit}}</div> 
            </w-flex>  
            <w-flex row>
              <div class="pa3 text-left">Gesamt:</div>
              <div class="spacer"></div>
              <div class="pa3 text-right">{{ compValuetotal }} {{unit}}</div>
            </w-flex>                                                                                                                                 
        </div>
    </div>
</template>

<script>
import { numberLiteralTypeAnnotation } from '@babel/types';

    export default {
        name: 'BarComponent', 
        props: {
            name: String,
            unit: String,
            value1: Number,
            value2: Number,
            value3: Number,
            valuetotal: Number,
            bgcolor: String,
        },
        data() {
            return {
                v1_proz: 20,
                v2_proz: 40,
                v3_proz: 60,                
            }
        },
        computed: {
            compValue1() {
                this.v1_proz = this.value1 * 100 / this.valuetotal;
                return this.value1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
            },
            compValue2() {
                this.v2_proz = this.value2 * 100 / this.valuetotal;
                return this.value2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
            },
            compValue3() {
                this.v3_proz = this.value3 * 100 / this.valuetotal;
                return this.value3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
            },
            compValuetotal() {                
                return this.valuetotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
            }, 
            bgColorClass() {
                return this.bgcolor;
            }            
        },        
    }
</script>