<template>
    <w-flex wrap>                             
        <div class="xs12 sm12 md4 lg4 xl4 pa1">
          <div class="primary-light2--bg py3 title1">
            <div class="pa3 text-left">Spannung eff L1-N</div>
            <div class="pa3 text-right">{{voltageL1N}} V</div>
          </div>
        </div>
        <div class="xs12 sm12 md4 lg4 xl4 pa1">
          <div class="primary-light2--bg py3 title1">
            <div class="pa3 text-left">Spannung eff L2-N</div>
            <div class="pa3 text-right">{{voltageL2N}} V</div>
          </div>
        </div>
        <div class="xs12 sm12 md4 lg4 xl4 pa1">
          <div class="primary-light2--bg py3 title1">
            <div class="pa3 text-left">Spannung eff L3-N</div>
            <div class="pa3 text-right">{{voltageL3N}} V</div>
          </div>
        </div>    
        <div class="xs12 sm12 md4 lg4 xl4 pa1">
          <div class="primary-light3--bg py3 title1">
            <div class="pa3 text-left">Spannung eff L1-L2</div>
            <div class="pa3 text-right">{{voltageL1L2}} V</div>
          </div>
        </div>
        <div class="xs12 sm12 md4 lg4 xl4 pa1">
          <div class="primary-light3--bg py3 title1">
            <div class="pa3 text-left">Spannung eff L2-L3</div>
            <div class="pa3 text-right">{{voltageL2L3}} V</div>
          </div>
        </div>
        <div class="xs12 sm12 md4 lg4 xl4 pa1">
          <div class="primary-light3--bg py3 title1">
            <div class="pa3 text-left">Spannung eff L3-L1</div>
            <div class="pa3 text-right">{{voltageL3L1}} V</div>
          </div>
        </div>                        
      </w-flex>
</template>
    
<script>
export default {
  name: 'Voltage', 
  data() {
    return {
      voltageL1N: '--,--',
      voltageL2N: '--,--',
      voltageL3N: '--,--',
      voltageL1L2: '--,--',
      voltageL2L3: '--,--',
      voltageL3L1: '--,--',
    }
  },
  inject: ['$callHub'], 
  mounted: function ()  {
    this.$callHub.client.on("SendData_Voltage", (data) => {
      var voltagedata = JSON.parse(data);
      this.voltageL1N = voltagedata.Voltage_L1N.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.voltageL2N = voltagedata.Voltage_L2N.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.voltageL3N = voltagedata.Voltage_L3N.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.voltageL1L2 = voltagedata.Voltage_L1L2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.voltageL2L3 = voltagedata.Voltage_L2L3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.voltageL3L1 = voltagedata.Voltage_L3L1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
    });
  }
}
</script>