<template>
    <w-flex wrap>                             
        <div class="xs12 sm12 md6 lg3 pa1">
          <div class="success-light1--bg py3 title1">
            <div class="pa3 text-left">Wirkleistung L1</div>
            <div class="pa3 text-right">{{powerActiveL1}} kW</div>
          </div>
        </div>
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light1--bg py3 title1">
            <div class="pa3 text-left">Wirkleistung L2</div>
            <div class="pa3 text-right">{{powerActiveL2}} kW</div>
          </div>
        </div>
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light1--bg py3 title1">
            <div class="pa3 text-left">Wirkleistung L3</div>
            <div class="pa3 text-right">{{powerActiveL3}} kW</div>
          </div>
        </div>  
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light1--bg py3 title1">
            <div class="pa3 text-left">Wirkleistung ges</div>
            <div class="pa3 text-right">{{powerActiveTotal}} kW</div>
          </div>
        </div> 

        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light2--bg py3 title1">
            <div class="pa3 text-left">Blindleistung L1</div>
            <div class="pa3 text-right">{{powerReactiveL1}} kvar</div>
          </div>
        </div>
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light2--bg py3 title1">
            <div class="pa3 text-left">Blindleistung L2</div>
            <div class="pa3 text-right">{{powerReactiveL2}} kvar</div>
          </div>
        </div>
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light2--bg py3 title1">
            <div class="pa3 text-left">Blindleistung L3</div>
            <div class="pa3 text-right">{{powerReactiveL3}} kvar</div>
          </div>
        </div>  
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light2--bg py3 title1">
            <div class="pa3 text-left">Blindleistung ges</div>
            <div class="pa3 text-right">{{powerReactiveTotal}} kvar</div>
          </div>
        </div>  
        
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light3--bg py3 title1">
            <div class="pa3 text-left">Scheinleistung L1</div>
            <div class="pa3 text-right">{{powerApparentL1}} kVA</div>
          </div>
        </div>
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light3--bg py3 title1">
            <div class="pa3 text-left">Scheinleistung L2</div>
            <div class="pa3 text-right">{{powerApparentL2}} kVA</div>
          </div>
        </div>
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light3--bg py3 title1">
            <div class="pa3 text-left">Scheinleistung L3</div>
            <div class="pa3 text-right">{{powerApparentL3}} kVA</div>
          </div>
        </div>  
        <div class="xs12 sm12 md6 lg3  pa1">
          <div class="success-light3--bg py3 title1">
            <div class="pa3 text-left">Scheinleistung ges</div>
            <div class="pa3 text-right">{{powerApparentTotal}} kVA</div>
          </div>
        </div> 
      </w-flex>
</template>
    
<script>
export default {
  name: 'Power',  
  data() {
    return {
      powerActiveL1: '--,--',
      powerActiveL2: '--,--',
      powerActiveL3: '--.--',   
      powerActiveTotal: '--,--',
      powerReactiveL1: '--,--',
      powerReactiveL2: '--,--',
      powerReactiveL3: '--.--',   
      powerReactiveTotal: '--,--',
      powerApparentL1: '--,--',
      powerApparentL2: '--,--',
      powerApparentL3: '--,--',   
      powerApparentTotal: '--,--',
    }
  },
  inject: ['$callHub'], 
  mounted: function ()  {
    this.$callHub.client.on("SendData_Power", (data) => {
      var powerdata = JSON.parse(data);
      this.powerActiveL1 = powerdata.PowerActive_L1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.powerActiveL2 = powerdata.PowerActive_L2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.powerActiveL3 = powerdata.PowerActive_L3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });   
      this.powerActiveTotal = powerdata.PowerActive_total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });   
      this.powerReactiveL1 = powerdata.PowerReactive_L1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.powerReactiveL2 = powerdata.PowerReactive_L2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.powerReactiveL3 = powerdata.PowerReactive_L3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });   
      this.powerReactiveTotal = powerdata.PowerReactive_total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });  
      this.powerApparentL1 = powerdata.PowerApparent_L1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.powerApparentL2 = powerdata.PowerApparent_L2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
      this.powerApparentL3 = powerdata.PowerApparent_L3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });   
      this.powerApparentTotal = powerdata.PowerApparent_total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });    
    });
  } 
}
</script>